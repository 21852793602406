import React, { useContext, useRef } from 'react';
import { Container } from 'react-bootstrap';
import gsap from 'gsap';
import { useIntersection } from 'react-use';

const Footer = () => {
  const sectionRef = useRef(null);

  const intersection = useIntersection(sectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.4,
  });

  // Animation for fading in
  const fadeIn = (element) => {
    gsap.to(element, 1, {
      opacity: 1,
      y: 0,
      ease: 'power3.out',
      stagger: {
        amount: 0.8,
      },
    });
  };
  // Animation for fading out
  const fadeOut = (element) => {
    gsap.to(element, 1, {
      opacity: 0,
      y: 200,
      ease: 'power3.out',
      stagger: {
        amount: 0.8,
      },
    });
  };
  // checking to see when the vieport is visible to the user
  // intersection && intersection.intersectionRatio < 0.4
  //   ? fadeOut('.footer-content')
  //   : fadeIn('.footer-content');

  return (
    <div ref={sectionRef}>
      <footer className="footer container navbar-static-bottom ">
        <Container>
          <div className="tag-india">
            Made with{' '}
            <span className="mx-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16.438"
                height="13.375"
                viewBox="0 0 16.438 13.375"
              >
                <path
                  id="heart"
                  d="M17.329,5.907a4.357,4.357,0,0,0-5.786,0l-1.085,1-1.086-1a4.355,4.355,0,0,0-5.785,0,3.977,3.977,0,0,0,0,5.969l6.871,6.306,6.871-6.306a3.979,3.979,0,0,0,0-5.969Z"
                  transform="translate(-2.239 -4.808)"
                  fill="#e14141"
                />
              </svg>
            </span>{' '}
            in
            <span className="ml-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="122.352"
                height="4.886"
                viewBox="0 0 122.352 4.886"
              >
                <g id="Group_66" data-name="Group 66" transform="translate(-1499 -3927)">
                  <path
                    id="Path_8"
                    data-name="Path 8"
                    d="M0,0H35.176l3.176,4.886H2.646Z"
                    transform="translate(1499 3927)"
                    fill="#f93"
                  />
                  <path
                    id="Path_9"
                    data-name="Path 9"
                    d="M0,0H35.176l3.176,4.886H2.646Z"
                    transform="translate(1541 3927)"
                    fill="#fff"
                  />
                  <path
                    id="Path_10"
                    data-name="Path 10"
                    d="M0,0H35.176l3.176,4.886H2.646Z"
                    transform="translate(1583 3927)"
                    fill="#138808"
                  />
                </g>
              </svg>
            </span>
          </div>
        </Container>
      </footer>
    </div>
  );
};

export default Footer;
