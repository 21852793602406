import React, { useContext, useState, useEffect } from 'react';
import { Container, Button, Navbar } from 'react-bootstrap';
import { Link } from 'gatsby';
import { animated, useTrail, useSpring } from 'react-spring';
import gsap, { TweenLite } from 'gsap';

import Fade from 'react-reveal/Fade';

const FullMenu = (props) => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isPartialUrl, setPartialUrl] = useState('');

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  //For partial greedy active nav link polyfill
  const isPartiallyActive = ({ isPartiallyCurrent }) =>
    isPartiallyCurrent ? { className: 'routes-menu-link-active' } : null;

  const isMainRoute = ({ isCurrent }) => {
    if (isCurrent) {
      return { className: 'routes-menu-link-active' };
    }
    return null;
  };

  useEffect(() => {
    if (props.active) {
      navItemsEffect();
    }
  }, [props.active]);

  const linkItems = [
    { id: 1, name: 'Home', url: '/' },
    { id: 2, name: 'Portfolio', url: '/portfolio' },
    { id: 3, name: 'Works', url: '/works' },
    { id: 4, name: 'Blog', url: '/blog' },
  ];
  const trail = useTrail(
    linkItems.length,
    {
      config: { mass: 1, tension: 120, friction: 14 },
      opacity: props.active ? 1 : 0,
      x: props.active ? 0 : 20,
      height: props.active ? 60 : 0,
      from: { opacity: 0, x: 20, height: 0 },
    },
    0.5
  );

  const socialEffect = () => {
    return gsap
      .timeline({
        defaults: {
          delay: 0.6,
          duration: 0.8,
          stagger: 0.3,
          ease: 'expo.out',
        },
      })
      .from('.social-link', {
        yPercent: 160,
        opacity: 0,
      })
      .to('.social-link', {
        yPercent: 0,
        opacity: 1,
      });
  };

  const navItemsEffect = (x = 1) => {
    return gsap
      .timeline({
        defaults: {
          delay: 0.8,
          duration: 1,
          stagger: 0.3,
          ease: 'expo.out',
        },
      })

      .from('.new-nav-item', {
        yPercent: 180,
        skewY: 4,
        opacity: 0,
      })
      .to('.new-nav-item', {
        yPercent: 0,
        skewY: 0,
        opacity: 1,
      });
  };

  const [{ rotate }, setRotate] = useSpring(() => ({
    from: {
      rotate: 0,
    },
    to: {
      rotate: 0,
    },
  }));

  const handleAnimatedMenuClose = () => {
    return gsap
      .timeline({
        defaults: {
          delay: 0,
          duration: 0.6,
          stagger: 0.5,
          ease: 'expo.out',
        },
        // onComplete: () => props.onClose(),
      })
      .from('.new-nav-item', {
        yPercent: 140,
        skewY: 5,
        opacity: 0,
      })
      .to('.new-nav-item', {
        yPercent: 0,
        skewY: 0,
        opacity: 1,
      })
      .reverse(0);
  };

  return (
    <animated.section id="overlay-menu" className="jumbotron" style={props.style}>
      <Navbar className="overlay-menu-nav">
        <Container className="justify-content-between" style={{paddingLeft: '4px'}}>
          <Navbar.Brand href="/" className="pb-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="18"
              viewBox="0 0 174.22 89.24"
              style={{ left: '-4px', position: 'relative', top: '2.0px' }}
            >
              <g data-name="Layer 2">
                <path
                  fill="#fefefe"
                  d="M95.17 89.24H71.23l1.9-1.33v-67.1L80.18 0h14.87l-1.8 1.24v86.67zM10.73 66.55S10.4 83.88 0 89.24h20.56s-8.83-6.81-9.83-22.69zM30.42 0H6.6l2.46 1.57 29.18 87.67h11.29l11.64-32.76-8.27 11.41zm121.21 0l-45.29 89.13 22.48.11L174.22 0zm-14.1 0c-13.07 1.92-24.82 13-28.94 29V0zm35.57 58.16v31.08h-28.62c19.91-2.46 27.73-25.38 28.62-31.08z"
                  data-name="Layer 1"
                ></path>
              </g>
            </svg>
          </Navbar.Brand>
          <animated.button
            onMouseEnter={() =>
              setRotate({
                from: {
                  rotate: 0,
                },
                to: {
                  rotate: 270,
                },
              })
            }
            onMouseLeave={() =>
              setRotate({
                from: {
                  rotate: 270,
                },
                to: {
                  rotate: 0,
                },
              })
            }
            onClick={() => props.onClose()}
            className="ghost-btn"
            style={{
              transform: rotate.interpolate((z) => `rotate(${z}deg)`),
            }}
          >
            <svg
              id="close"
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 20.964 20.964"
            >
              <path
                id="close-2"
                data-name="close"
                d="M6.91,4.929A1.4,1.4,0,0,0,4.929,6.91L13.019,15l-8.09,8.09A1.4,1.4,0,1,0,6.91,25.071L15,16.981l8.09,8.09a1.4,1.4,0,0,0,1.981-1.981L16.981,15l8.09-8.09A1.4,1.4,0,0,0,23.09,4.929L15,13.019Z"
                transform="translate(-4.518 -4.518)"
                fill="#fff"
              />
            </svg>
          </animated.button>
        </Container>
      </Navbar>

      <Container className="px-0">
        <ul className="routes-menu">
          {trail.map(({ x, height, ...rest }, index) => (
            <div
              key={linkItems[index].id}
              className="trails-text"
              style={{
                ...rest,
                transform: x.interpolate((x) => `translate3d(0,${x}px,0)`),
                overflow: 'hidden',
              }}
            >
              <li style={{ height }} className="hero-title new-nav-item">
                <Link
                  data-num={index + 1}
                  partiallyActive
                  to={linkItems[index].url}
                  activeClassName="routes-menu-link-active"
                  className="anchor-nav mainmenu__item"
                  activeClassName={linkItems[index].url === '/' ? undefined : undefined}
                  getProps={linkItems[index].url === '/' ? isMainRoute : isPartiallyActive}
                >
                  {linkItems[index].name}
                </Link>
              </li>
            </div>
          ))}
        </ul>

        <div className="social-links-container d-flex w-100">
          <Fade delay={1800} duration={1000} bottom distance="20px">
            <a className="social-link" target='_blank' href='https://github.com/M-Zabi'>Github</a>
          </Fade>

          <Fade delay={2000} duration={1000} bottom distance="20px">
            <a className="social-link" target='_blank' href='https://dribbble.com/zarkaanstudios'>Dribbble</a>
          </Fade>
          <Fade delay={2200} duration={1000} bottom distance="20px">
            <a className="social-link" target='_blank' href='https://www.linkedin.com/in/mohammed-zabiullah-834975157/'>LinkedIn</a>
          </Fade>
        </div>
      </Container>
    </animated.section>
  );
};

export default FullMenu;
